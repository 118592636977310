import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getPosts } from './actions/posts'
import { Container, Grow, Grid } from '@mui/material'
import memories from './images/memories.png'
import Posts from './components/posts/posts'
import Form from './components/form/Form'
import { containerStyles, StyledAppBar, StyledHeading, StyledImage } from './styles'

const App = () => {
   const [currentId, setCurrentId] = useState(null)
   const dispatch = useDispatch();

   useEffect(() => {
      dispatch(getPosts());
   }, [currentId, dispatch]);

   return (
      <Container maxWidth="lg">
         <StyledAppBar position="static" color="inherit">
            <StyledHeading align="center">Memories</StyledHeading>
            <StyledImage src={memories} alt="memories" height="60" />
         </StyledAppBar>
         <Grow in>
            <Container>
               <Grid sx={containerStyles} container justifyContent="space-between" alignItems="stretch" spacing={3}>
                  <Grid item xs={12} sm={7}>
                     <Posts setCurrentId={setCurrentId}/>
                  </Grid>
                  <Grid item xs={12} sm={4}>
                     <Form currentId={currentId} setCurrentId={setCurrentId}/>
                  </Grid>
               </Grid>
            </Container>
         </Grow>
      </Container>
   );
}

export default App;
