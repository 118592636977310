import React from 'react'
import Post from './post/post'
import { CircularProgress, Grid } from '@mui/material'
import { useSelector } from 'react-redux'
import { mainContainerStyles, /*smMarginStyles, actionDivStyles*/ } from './styles';

const Posts = ({ setCurrentId }) => {
    const posts = useSelector((state) => state.posts)
    console.log(posts)

    return(
        !posts.length ? ( <CircularProgress /> ) : (
            <Grid sx={mainContainerStyles} container alignItems='stretch' spacing={3}>
                {posts.map((post) => (
                    <Grid key={post._id} item xs={12} sm={6}>
                        <Post post={post} setCurrentId={setCurrentId}/>
                    </Grid>
                ))}
            </Grid>
        )
    )
}

export default Posts