export const mainContainerStyles = {
    display: 'flex',
    alignItems: 'center',
  };
  
  export const smMarginStyles = (theme) => ({
    margin: theme.spacing(1),
  });
  
  export const actionDivStyles = {
    textAlign: 'center',
  };
  