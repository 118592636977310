import { styled, createTheme } from "@mui/material/styles";
import { AppBar } from "@mui/material";

export const theme = createTheme()

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  borderRadius: 15,
  margin: '30px 0',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
}));

export const StyledHeading = styled('h2')(({ theme }) => ({
  color: 'rgba(0,183,255, 1)',
}));

export const StyledImage = styled('img')(({ theme }) => ({
  marginLeft: '15px',
}));

export const containerStyles = {
  display: 'flex',
  flexDirection: 'row', // Default style
  [theme.breakpoints.down('sm')]: {
      flexDirection: 'column-reverse', // Style for small screens
  },
};
