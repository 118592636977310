import React from 'react'
import { Card, CardActions, CardContent, CardMedia, Button, Typography, /*textFieldClasses*/ } from '@mui/material'
import { mediaStyles, /*borderStyles, fullHeightCardStyles,*/ cardStyles, overlayStyles, overlay2Styles, /*gridStyles,*/ detailsStyles, titleStyles, cardActionsStyles } from './styles';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import moment from 'moment'
import { useDispatch } from 'react-redux'
import { deletePost, likePost } from '../../../actions/posts';

const Post = ({ post, setCurrentId }) => {

    const dispatch = useDispatch()

    return(
        <Card sx={cardStyles}>
            <CardMedia sx={mediaStyles} image={post.selectedFile} title={post.title} />
            <div style={overlayStyles}>
                <Typography variant='h6'>{post.creator}</Typography>
                <Typography variant='body2'>{moment(post.createdAt).fromNow()}</Typography>
            </div>
            <div style={overlay2Styles}>
                <Button 
                    style={{color: 'white'}} 
                    size='small' onClick={() => 
                    setCurrentId(post._id)
                }>
                    <MoreHorizIcon fontSize='default'/>
                </Button>
            </div>
            <div style={detailsStyles}>
                <Typography variant='body2' color='textSecondary'>{post.tags.map((tag) => `#${tag} `)}</Typography>
            </div>
            <Typography sx={titleStyles} variant='h5' gutterBottom>{post.title}</Typography>
            <CardContent>
                <Typography variant='body2' color='textSecondary' component='p'>{post.message}</Typography>
            </CardContent>
            <CardActions sx={cardActionsStyles}>
                <Button size='small' color='primary' onClick={() => dispatch(likePost(post._id))}>
                    <ThumbUpAltIcon fontSize='small' />
                    &nbsp; Like &nbsp;
                    {post.likeCount}
                </Button>
                <Button size='small' color='primary' onClick={() => dispatch(deletePost(post._id))}>
                    <DeleteIcon fontSize='small' />
                    Delete
                </Button>
            </CardActions>
        </Card>
    )
}

export default Post