export const mediaStyles = {
    height: 0,
    paddingTop: '56.25%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    backgroundBlendMode: 'darken',
};
  
export const borderStyles = {
    border: 'solid',
};
  
export const fullHeightCardStyles = {
    height: '100%',
};
  
export const cardStyles = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRadius: '15px',
    height: '100%',
    position: 'relative',
};
  
export const overlayStyles = {
    position: 'absolute',
    top: '20px',
    left: '20px',
    color: 'white',
};
  
export const overlay2Styles = {
    position: 'absolute',
    top: '20px',
    right: '20px',
    color: 'white',
};
  
export const gridStyles = {
    display: 'flex',
};
  
export const detailsStyles = {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '20px',
};
  
export const titleStyles = {
    padding: '0 16px',
};
  
export const cardActionsStyles = {
    padding: '0 16px 8px 16px',
    display: 'flex',
    justifyContent: 'space-between',
};
  