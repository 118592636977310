import React, { useEffect, useState } from 'react'
import { Root, StyledPaper, StyledForm, FileInput, ButtonSubmit, StyledHeading } from './styles'
import { TextField, Button } from '@mui/material'
import FileBase from 'react-file-base64'
import { useDispatch, useSelector } from 'react-redux'
import { createPost, updatePost } from '../../actions/posts'

const Form = ({ currentId, setCurrentId }) => {

    const [postData, setPostData] = useState({ creator:'', title:'', message:'', tags:'', selectedFile:'' })
    const post = useSelector((state) => currentId ? state.posts.find((p) => p._id === currentId) : null)
    const dispatch = useDispatch()

    useEffect(() => {
        if(post) setPostData(post)
    }, [post])
    
    const handleSubmit = (e) => {
        e.preventDefault()

        if(currentId) {
            dispatch(updatePost(currentId, postData))
        } else {
            dispatch(createPost(postData))
        }
        
        clear()
    }

    const clear = () => {
        setCurrentId(null)
        setPostData({ creator:'', title:'', message:'', tags:'', selectedFile:'' })
    }

    return(
        <Root>
            <StyledPaper>
                <StyledForm autoComplete='off' noValidate onSubmit={handleSubmit}>
                <StyledHeading>{currentId ? 'Editing' : 'Creating'} a Memory</StyledHeading>
                <TextField name='creator' variant='outlined' label='Creator' fullWidth value={postData.creator} onChange={(e) => setPostData({ ...postData, creator: e.target.value })} />
                <TextField name='title' variant='outlined' label='Title' fullWidth value={postData.title} onChange={(e) => setPostData({ ...postData, title: e.target.value })} />
                <TextField name='message' variant='outlined' label='Message' fullWidth value={postData.message} onChange={(e) => setPostData({ ...postData, message: e.target.value })} />
                <TextField name='tags' variant='outlined' label='Tags' fullWidth value={postData.tags} onChange={(e) => setPostData({ ...postData, tags: e.target.value.split(',') })} />
                <FileInput>
                    <FileBase type='file' multiple={false} onDone={({base64}) => setPostData({ ...postData, selectedFile: base64 })} />
                </FileInput>
                <ButtonSubmit variant='contained' color='primary' size='large' type='submit' fullWidth>Submit</ButtonSubmit>
                <Button variant='contained' color='secondary' size='small' onClick={clear} fullWidth>Clear</Button>
                </StyledForm>
            </StyledPaper>
        </Root>
    )
}

export default Form